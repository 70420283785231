import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Developer.css';
import { Link as Anchor } from 'react-router-dom';

export default function Developer() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const isModalClosed = sessionStorage.getItem('modalClosed');
        if (!isModalClosed) {
            setModalIsOpen(true);
        }
    }, []);

    const handleCloseModal = () => {
        sessionStorage.setItem('modalClosed', 'true');
        setModalIsOpen(false);
    };

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleWhatsappMessage = () => {
        const phoneNumber = 543875683101;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        ""
    );
}
